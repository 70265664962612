import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47')
];

export const server_loads = [2,3];

export const dictionary = {
		"/(home)": [~6,[2]],
		"/_component_example": [~28],
		"/admin/member": [~29,[3]],
		"/admin/model": [~30,[3]],
		"/admin/plan": [~31,[3]],
		"/article/convert": [~33,[4]],
		"/article/create": [~34,[4]],
		"/article/[id]": [~32,[4]],
		"/(home)/ask": [~7,[2]],
		"/(home)/assistant": [~8,[2]],
		"/(home)/assistant/create": [~11,[2]],
		"/(home)/assistant/thread/[id]": [~12,[2]],
		"/(home)/assistant/[id]": [~9,[2]],
		"/(home)/assistant/[id]/edit": [~10,[2]],
		"/auth/action": [~35],
		"/auth/email-confirmation": [~36],
		"/auth/email-verification": [~37],
		"/auth/login": [~38],
		"/auth/password/reset": [~39],
		"/auth/signup": [~40],
		"/content-conversion/[id]": [~41,[5]],
		"/(home)/content-generation": [~13,[2]],
		"/(home)/content-generation/create": [~16,[2]],
		"/(home)/content-generation/[id]": [~14,[2]],
		"/(home)/content-generation/[id]/edit": [~15,[2]],
		"/(home)/image-studio": [~17,[2]],
		"/(home)/image-studio/create": [~18,[2]],
		"/image-studio/[id]": [~42],
		"/invitation": [~43],
		"/(home)/measure": [~19,[2]],
		"/(home)/measure/[id]": [~20,[2]],
		"/(home)/measure/[id]/content-generation": [~21,[2]],
		"/(home)/mfa": [~22,[2]],
		"/(home)/mfa/add": [~23,[2]],
		"/(home)/search": [~24,[2]],
		"/(home)/search/[id]": [~25,[2]],
		"/share/[id]": [~44],
		"/(home)/subscription/failed": [~26,[2]],
		"/subscription/select": [~45],
		"/team": [~46],
		"/team/create": [~47],
		"/(home)/thread/[id]": [~27,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';